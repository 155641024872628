import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {EmployeeLite, RouteLite} from 'app/blocks/model/site-visit.model';
import {IStockTakeItem} from 'app/blocks/model/stock-take-item.model';
import {IWarehouse} from 'app/blocks/model/warehouse.model';

export class VehicleLite {
    id: number;
    vehicleId: string;
    description: string;
    licencePlate: string;
    vehicleTypeDesc: string;
    make: string;
    model: string;
    maxWeight: number;
    maxPackageCount: number;

    constructor(vehicle?: any) {
        if (vehicle) {
            this.id = vehicle.id;
            this.vehicleId = vehicle.vehicleId;
            this.description = vehicle.description;
            this.licencePlate = vehicle.licencePlate;
            this.vehicleTypeDesc = vehicle.vehicleTypeDesc;
            this.make = vehicle.make;
            this.model = vehicle.model;
            this.maxWeight = vehicle.maxWeight;
            this.maxPackageCount = vehicle.maxPackageCount;
        }
    }
}

export interface IStockTake extends IBaseEntity {
    id?: number;
    route?: RouteLite;
    vehicle?: VehicleLite;
    driver?: EmployeeLite;
    date?: string;
    time?: string;
    items?: IStockTakeItem[];
    pdfAvailable?: boolean;
    postAdjustCompleted?: boolean;
    isAdjustment?: string;
    total?: number;
    notes?: string;
    warehouse?: any;
}

export class StockTake extends BaseEntity implements IStockTake {
    public id?: number;
    public route?: RouteLite;
    public vehicle?: VehicleLite;
    public driver?: EmployeeLite;
    public date?: string;
    public time?: string;
    public items?: IStockTakeItem[];
    public pdfAvailable?: boolean;
    public postAdjustCompleted?: boolean;
    public isAdjustment?: string;
    public total?: number;
    public notes?: string;
    public warehouse?: IWarehouse;

    constructor(stockTake?: any) {
        super();
        if (stockTake) {
            this.id = stockTake.id;
            this.route = stockTake.route;
            this.vehicle = stockTake.vehicle;
            this.driver = stockTake.driver;
            this.date = stockTake.date;
            this.time = stockTake.time;
            this.items = stockTake.items;
            this.pdfAvailable = stockTake.pdfAvailable;
            this.postAdjustCompleted = stockTake.postAdjustCompleted;
            this.isAdjustment = stockTake.isAdjustment;
            this.total = stockTake.total;
            this.notes = stockTake.notes;
            this.warehouse = stockTake.warehouse;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): IStockTake {
        return new StockTake(this);
    }
}
